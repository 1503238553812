.Toastify__toast {
}
.Toastify__close-button {
  color: #000 !important;
}
.Toastify__toast--error {
  opacity: 1 !important;
  background-color: #fff !important;
  color: #000;
  border-radius: 10px !important;
  border: solid $red;
  border-width: 10px 0px 0px 0px;
}
.Toastify__toast--success {
  opacity: 1 !important;
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 10px !important;
  border: solid $green;
  border-width: 10px 0px 0px 0px;
}
.Toastify__toast--warning {
  opacity: 1 !important;
  background-color: #fff !important;
  color: #000;
  border-radius: 10px !important;
  border: solid $orange;
  border-width: 10px 0px 0px 0px;
}
.Toastify__progress-bar {
  background-color: $info !important;
  height: 3px !important;
  opacity: 0.5 !important;
}
