.scanner-cancel-btn{
  position:absolute;
  bottom: 0px;
  left: 0px;
}
.scanner-video{
  width: 100%;
}
.scanner-video-wrapper{
  position: relative;
  height: 50vh;
  margin-top: 25vh;
}
.scanner-overlay-wrapper{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,1);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delivery-modal{
  text-align: center;
  min-height: 100px;
}
.delivery-assigned-locker-number{
  font-size: 4em;
}
.scanner-crosshairs{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-top: 3px solid #fff;
  height: 1px;
  width: 100vw;
  z-index: 999;
  animation: scanner-anim 3s infinite ease-in-out;
  box-shadow: 0px 0px 30px 3px #fff;
}
@keyframes scanner-anim {
  0% {
    transform: translate(0px, -150px);
  }
  50% {
    transform: translate(0px, 150px);
  }
  100% {
    transform: translate(0px, -150px);
  }
}