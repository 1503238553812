// =========================================================================================
//   File Name: app loader.scss
//   Description: Custom vuexy app loader component.
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuexy React Admin Template
//   Version: 1.0
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================

// Component: app loader
// ========================================================================

.spinner-container{
  width: 100px;
  height: 100px;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.modal-spinner{
    top: 20px;
  }
  &.dark-spinner{
    background-color: #10163a;
  }
  &.light-spinner{
    background-color: #fff;
  }
}
.spinner-logo-wrapper{
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.fallback-logo {
  width: 50%;
  align-self: center;
}
.spinner-relative{
  position: relative;
  background-color: transparent;
  box-shadow: none !important;
}
.loader {
  --border-width: 5px;

  height: 80px;
  width: 80px;
  border-radius: 50%;

  /* 0.5px's are needed to avoid hard-stopping */
  --mask: radial-gradient(
    farthest-side,
    transparent calc(100% - var(--border-width) - 0.5px),
    #000 calc(100% - var(--border-width) + 0.5px)
  );
  -webkit-mask: var(--mask);
          mask: var(--mask);

  /* we're using two half linear-gradient which is masked by the radial-gradient */
  background: linear-gradient(to top, rgba(14, 211, 146, 0.8), rgba(35, 172, 205, 0.4)) 100% 0/50% 100% no-repeat,
              linear-gradient(rgba(35, 172, 205, 0.4) 50%, transparent 95%) 0 0/50% 100% no-repeat;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
