@import "./bootstrap.scss";
@import "./components.scss";
@import "./bootstrap-extended.scss";
@import "./colors.scss";
@import "./iconfont.scss";
@import "./custom-rtl.scss";
@import "../client-variables/overrides.scss";


.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

.view-more-btn-wrapper{
  padding: 10px;
  display: flex;
  justify-content: center;
}
.pin-input{
  min-width: 50px !important;
}
.cursor-pointer:hover{
  cursor: pointer;
}
.margin-bottom{
  margin-bottom: 20px;
}
.editor-class {
  background-color: white;
  height: 55vh !important;
  overflow-y: scroll !important;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  padding: 0 10px 0 10px;
}

.del-text{
  text-decoration: line-through !important;
}

.font-weight-semi-bold{
  font-weight: 500;
}

.button-hover{
  &:hover {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    -webkit-box-shadow: 0 8px 15px -8px #aaa;
    box-shadow: 0 8px 15px -8px #aaa;
  }
}

.tooltip { 
  position: relative;
  z-index: 10000 !important
}