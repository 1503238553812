/*=========================================================================================
    File Name: navbar.scss
    Description: navbar specific scss.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy React Admin Template
    Version: 1.0
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/



// Navbar base
// ------------------------------

// Base
  .navbar-language{
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .navbar-muted-text{
    font-size: 11px;
    color: 'gray';
    float: right;
  }
  .navbar-search-wrapper{
    position: relative;
  }
  .navbar-search-input-wrapper{
   width: 100%;
  }
  .navbar-search-input{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 60px;
    width: 80%;
  }
  .navbar-search-icon{
    margin-right: 20px;
    margin-left: 20px;
  }
  .navbar-search-icon:hover{
    cursor: pointer;
  }
  .navbar-icon-wrapper{
    display: flex;
    align-items: center;
    padding: 15px;
  }
  .navbar-icon{
    height: 100%;
  }
