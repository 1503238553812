.notif-icon{
  height: 100%;
}
.notif-dropdown-item{
  width: 100%;
  white-space: pre-wrap;
  padding: 0px !important;
}
.dropdown-item:focus{
  outline-width: 0px !important;
}
.notifications-header{
  text-align: center;
  font-size: 1.1em;
}
.notifications-menu-header{
  padding: 20px;
  border-bottom: 1px solid $gray-200;
}
.notifications-item-name{
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}
.pulse-red{
  box-shadow: 0 0 0 0 rgba(184, 95, 95, 1);
	transform: scale(1);
	animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(184, 95, 95, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(184, 95, 95, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(184, 95, 95, 0);
	}
}
